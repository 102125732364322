var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "big-data-container",
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "content-input-box", style: `height: 100%;` },
          [
            _c(
              "div",
              { staticClass: "breadcrumb-box" },
              [
                _c(
                  "el-breadcrumb",
                  { attrs: { "separator-class": "el-icon-arrow-right" } },
                  [
                    _c(
                      "el-breadcrumb-item",
                      { attrs: { to: { path: "/site" } } },
                      [_vm._v("站点管理")]
                    ),
                    _c("el-breadcrumb-item", [_vm._v("添加站点")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { height: "100%" } },
                  [
                    _c("detail-edit", {
                      attrs: {
                        company: _vm.company,
                        "is-add": true,
                        "is-detail": false,
                      },
                      on: { close: _vm.onCloseEdit },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }