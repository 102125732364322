<template>
  <div class="big-data-container" v-loading="loading">
    <div class="content">
      <div class="content-input-box" :style="`height: 100%;`">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{path: '/site'}">站点管理</el-breadcrumb-item>
            <el-breadcrumb-item>添加站点</el-breadcrumb-item>
          </el-breadcrumb>
          <div style="height: 100%;">
            <detail-edit :company="company" :is-add="true" :is-detail="false" @close="onCloseEdit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bizApi from "@/api/business";

import DetailEdit from "./detail/detail-edit";

export default {
  components: {
    DetailEdit
  },
  data () {
    return {
      breadcrumb: {
        companyName: ""
      },
      company: null,
      bizId: "",
    }
  },
  created () {
    this.bizId = this.$route.query.bizId;
    this.getBizData();
  },
  methods: {
    async getBizData () {
      const res = await bizApi.detail(this.bizId);
      this.company = res;

      this.breadcrumb.companyName = res.companyName;
    },
    getGotoObj () {
      return {
        path: `/site?bizId=${this.bizId}`
      }
    },
  }
}
</script>